import {
  Box,
  Grid,
  Hidden,
  Typography,
} from '@material-ui/core';

import BrandLogo from '../molecules/BrandLogo';
import Image from '../atoms/Image';
import ProvenExpertGoogleStars from './ProvenExpertGoogleStars';
import { makeStyles } from '@material-ui/styles';

export default function BrandAttentionBar(props) {
  const {
    isLogoVisible,
    isProvenExpertVisible,
    isSloganVisible,
    isLinkDisabled,
    slogan,
    logo,
    logoWidth,
  } = props;
  const classes = useStyles();

  return (
    <a
      style={isLinkDisabled ? { pointerEvents: 'none' } : null}
      href={isLinkDisabled ? '#' : '/'}
      target={isLinkDisabled ? null : '_blank'}
      className={classes.root} rel="noreferrer"
    >
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        {isLogoVisible && (
          <Hidden smDown>
            <Grid item>
              <Box textAlign="right" style={{ width: 200 }}>
                <Image
                  src="/images/immowelt-logo.svg"
                  alt="Ein Service von Immowelt"
                  width="200"
                  height="21"
                />
              </Box>
            </Grid>
          </Hidden>
        )}
        <Grid item>
          <Box display="flex" flexDirection="column" alignItems="center">
            {logo ? (
              <img
                className={classes.logo}
                src={
                  logo.format?.small?.url
                  ?? logo.format?.medium?.url
                  ?? logo.format?.large?.url
                  ?? logo.url
                }
                style={logoWidth ? {
                  width: logoWidth,
                } : null}
                alt=""
              />
            ) : (
              <BrandLogo className={classes.logo}/>
            )}
            {isSloganVisible && (
              <Typography variant="caption" align="center" className={classes.slogan}>
                {slogan || 'Ihr Experte für Baufinanzierung & Bauförderung'}
              </Typography>
            )}
            {isProvenExpertVisible && (
              <ProvenExpertGoogleStars/>
            )}
          </Box>
        </Grid>
        {isProvenExpertVisible && (
          <Hidden smDown>
            <Grid item>
              <Image
                src="/images/px-seal-1.png"
                alt="Proven Expert"
                width="172"
                height="125"
              />
            </Grid>
          </Hidden>
        )}
      </Grid>
    </a>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  logo: {
    display: 'block',
    width: 160,
    height: 'auto',
  },
  slogan: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
